import { SideLayerElementCompProps } from "../models";
import { Show, createEffect, onMount } from "solid-js";
import { $dashboard_active_layer_item_idx, $dashboard } from "../store";
export function SideLayerElement(props: SideLayerElementCompProps) {
  createEffect(() => {
    // console.log("effect from SideLayerElement :: ");
    $dashboard.set((s) => ({ ...s, layer: { ...s.layer, items: props.items } }));
  });
  onMount(() => {
    let bc = props.breadcrumb;
    if (!!bc) {
      bc.push({
        title: "UNDEFINED",
        to: undefined,
      });
    }
    // console.log("bc from element is :: ", bc);
    $dashboard.set((s) => ({
      ...s,
      layer: { ...s.layer, nonAnchorItems: props.nonAnchorItems, items: props.items },
      breadcrumb: bc,
    }));
  });
  return (
    <Show
      when={
        $dashboard_active_layer_item_idx.value !== undefined &&
        props.items &&
        props.items.length > 0 &&
        $dashboard_active_layer_item_idx.value < props.items.length
      }
      fallback={props.children}
    >
      <Show when={props.items[$dashboard_active_layer_item_idx.value].element} fallback={props.children}>
        {props.items[$dashboard_active_layer_item_idx.value].element()}
      </Show>
    </Show>
  );
}
